$intro-color: #191919;
$success-color: #00cd00;
$construction-color: #ff7900;

@keyframes dissipate {
  100% {
    opacity: 0;
    display: none !important;
    pointer-events: none;
  }
}

@keyframes construction {
  0% {
    background-color: $intro-color;
  }

  100% {
    background-color: $construction-color;
  }
}

.intro-pane {
  width: 100%;
  height: 100%;
  max-height: 100%;
  z-index: 999;
  background-color: $intro-color;
  position: absolute;
  opacity: 1;
}

.intro-pane.success.hide {
  animation: dissipate 1s ease 3s 1 normal forwards;
}

.intro-pane.construction {
  animation: construction 600ms linear 0s 1 normal forwards;
}

@keyframes intro-loading-enter {
  0% {
    opacity: 0;
    margin-bottom: 250px;
  }

  100% {
    opacity: 1;
    margin-bottom: 0;
  }
}

.intro-loading {
  overflow: hidden;
  display: flex;
  opacity: 0;
  margin-bottom: 250px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  animation: intro-loading-enter 750ms ease 250ms 1 normal forwards;
}

.intro-loading h1 {
  width: auto;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  color: #fefefe;
  text-shadow: 2px 2px #080808;
}

.intro-loading h4 {
  width: auto;
  text-align: center;
  color: #fefefe;
  text-shadow: 2px 2px #080808;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.intro-loading .spinner {
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.intro-loading .spinner:before {
  content:'';
  border-radius: 50%;
  border-top: 12px solid #735243;
  border-right: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid transparent;
  width: 150px;
  height: 150px;
  position: absolute;
  top: -12px;
  left: -12px;
  animation: spin 2s linear infinite;
}

.intro-loading .spinner > img {
  position: fixed;
  width: 120px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
}

.intro-loading .spinner > img.show {
  animation: hide-img 500ms ease 0s 1 normal forwards;
}

@keyframes hide-img {
  to {
    opacity: 0;
  }
}

.intro-loading .spinner > .check {
  position: absolute;
  transform: scale(75%);
  fill: none;
  stroke: #735243;
  stroke-width: 20;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
  top: 15px;
  left: -10px;
}

.intro-loading .spinner > .check.show {
  animation: check 1s ease 750ms 1 normal forwards;
}

@keyframes check {
  to {
    stroke-dashoffset: 0;
  }
}

.intro-loading .intro-br {
  margin-top: 20px;
  margin-bottom: 20px;
}

@keyframes intro-loading-exit {
  0% {
    opacity: 1;
    margin-top: 0px;
  }

  100% {
    opacity: 0;
    margin-top: 200px;
    display: none;
  }
}

.intro-loading.exit {
  opacity: 1;
  margin-bottom: 0px;
  margin-top: 0px;
}

.intro-loading.exit.success {
  animation: intro-loading-exit 750ms ease 2s 1 normal forwards;
}

.intro-loading.exit.construction {
  animation: intro-loading-exit 750ms ease 0s 1 normal forwards;
}

.center-page-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 100%;
}

@keyframes enter-custom-pane {
  0% {
    opacity: 0;
    margin-top: 250px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.construction-pane {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 250px;
  opacity: 0;
  animation: enter-custom-pane 600ms ease 0s 1 normal forwards;
}

.construction-pane h1 {
  width: auto;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  text-shadow: 1px 1px #fefefe;
}

.construction-pane h4 {
  width: auto;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px #fefefe;
}

@keyframes footer-enter {
  0% {
    bottom: -75px;
  }

  100% {
    bottom: 0px;
  }
}

.construction-footer {
  position: fixed;
  min-width: 100%;
  bottom: -75px;
  height: 60px;
  display: flex;
  justify-content: start;
  background-color: #191919;
  box-shadow: 0px -2px 2px 2px #191919;
  animation: footer-enter 750ms ease 1s 1 normal forwards;
}

.construction-footer .item:nth-child(1) {
  margin-left: 25%;
  margin-right: auto;
}

.construction-footer .item:nth-child(n+1) {
  margin-left: auto;
  margin-right: auto;
}

@keyframes footer-mobile-enter {
  0% {
    bottom: -115px;
  }

  100% {
    bottom: 0px;
  }
}

.construction-footer.mobile {
  bottom: -115px;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  animation: footer-mobile-enter 750ms ease 1s 1 normal forwards;
}

.construction-footer.mobile .item {
  margin-left: auto;
  margin-right: auto;
}