.square-picture {
  background-color: #fdfdfd;
  padding: 40px;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(7.5deg);
  box-shadow: 5px 5px 3px black;
}

.square-picture-mobile {
  padding: 20px !important;
  margin-bottom: 20px;
}

.home-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 94vh;
  max-height: 999px;
  width: 100%;
}

.home-header .container {
  position: relative;
  z-index: 0;
}

.home-header .container .adjust-center {
  margin-top: -50px;
}

.home-header .header-img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  filter: blur(6px);
  z-index: -1;
}

.home-header h1 {
  font-size: 3.0rem;
  font-style: italic;
  font-weight: bolder;
  text-shadow: 3px 3px #0c0c0c;
}

.home-header h4 {
  text-shadow: 2px 2px #0c0c0c;
}

.home-header p {
  text-shadow: 1px 1px #0c0c0c;
}

.main-content {
  position: relative;
  width: 100%;
}

.main-content h1 {
  font-weight: bold;
  text-shadow: 2px 2px #0c0c0c;
}

.main-content .section {
  margin-top: 20vh;
  margin-bottom: 20vh;
}

.main-content .section h3 {
  font-style: italic;
  text-shadow: 2px 2px #0c0c0c;
}

.main-content .section hr {
  background-color: #fcfcfc;
}

.selected-section {
  border-radius: 15px;
  background-color: #131313;
}

.selected-section h1,
.selected-section h2,
.selected-section h3,
.selected-section h4,
.selected-section h5,
.selected-section h6,
.selected-section p,
.selected-section li {
  color: #fcfcfc;
}

.selected-section a h1,
.selected-section a h4 {
  font-style: italic;
  text-shadow: 2px 2px #0c0c0c;
}

.selected-section a:hover h1,
.selected-section a:hover h4 {
  text-decoration: underline;
}

.job-card {
  background-color: #fdfdfd;
  padding: 20px;
  box-shadow: 5px 5px 3px black;
}

.job-card h3 {
  text-shadow: none !important;
}

.job-card a:hover h5 {
  text-decoration: underline;
}

.job-card .img-section {
  width: 25%;
  display: flex;
  margin-left: 0;
  margin-right: auto;
}

.job-card .img-section img {
  margin-top: auto;
  margin-bottom: auto;
}

.job-card .title-section {
  width: 70%;
  margin-left: auto;
  margin-right: 0;
}

.resume-table th,
.resume-table tr {
  color: #fcfcfc;
}